<template>
  <div v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="玩命加载中...">
    <div style="border-bottom: 2px solid #dddddd;">
      <div class="register-main-top">
        <div class="search-logo">
          <img alt="logo" src="../../public/LOGO.svg">
          <!-- <span>闪开</span><span style="font-family: FZLanTingHeiS-R-GB;">·</span><span>新媒</span> -->
          <span>嘉兴智行物联网技术有限公司</span>
        </div>
        <span style="position: absolute;right: 0;">
          <span class="buttom-txt register-goLogin" style="margin-right: 10px;" @click="goNew('/')">[返回首页]</span>
          hi，欢迎来到嘉兴智行物联网技术有限公司！
        </span>
      </div>
    </div>

    <div class="container step-box">
      <div class="step-son" :class="stepActive == 1 ? 'active-step' : 'first-step'">1、充值</div>
      <div class="arrow-right" :class="stepActive == 1 ? 'arrow-right-avtive' : ''"></div>
      <div class="arrow-left"></div>
      <div class="step-son" :class="stepActive == 2 ? 'active-step' : ''">2、发布</div>
    </div>

    <div class="register-container">
      <div v-if="stepActive == 1">
        <i class="el-icon-success order-icon"></i>
        <div class="order-box">
          <h3>订单提交成功，请尽快使用<span style="font-size: 22px;color: #e79b29;margin: 0 5px;">微信</span>完成支付~</h3>
          <!-- <span class="buttom-txt">订单号：{{ orderNo }}</span> -->
          <br />
          <span style="font-size: 18px;color: #e6a23c;margin-right: 5px;" id="countdown"></span><span>内支付</span><br>
          <span class="buttom-txt">收货信息：</span>
        </div>
        <div style="text-align: center;">
          <h4>应付金额：<span style="font-size: 22px;color: #e79b29;margin-right: 5px;">{{ amount }}元</span></h4>
          <div><img :alt="'\n加载失败\n请刷新页面重试'" class="alt-multiline" :src="PGurl"></div>
          <span class="buttom-txt">首次使用请下载手机微信</span>
        </div>
      </div>
      <div v-else>
        <p>恭喜充值成功！<span style="font-size: 22px;color: #e6a23c;">{{ seconds }}</span> 秒后自动跳转到首页</p>
        <p style="font-size: 12px;color: #83b24c;cursor: pointer;" @click="goNew('/')">如果你的浏览器没反应，请点击这里...</p>
      </div>
    </div>
    <p class="buttom-txt">© 嘉兴智行物联网技术有限公司 <a href="http://beian.miit.gov.cn/" target="_blank"
        class="a-herf">浙ICP备15017943号-6 </a></p>
    <p class="buttom-txt">地址：浙江省嘉兴市南湖区智慧产业园9幢5楼 <span style="margin-left: 20px;">客服电话：400-667-9990</span></p>
  </div>
</template>
      
<script>

export default {
  name: 'Recharge',
  data() {
    return {
      stepActive: 1,
      seconds: 3,
      interval: null,
      loading: false,
      countdownTimer: null,
      orderSeconds: 1800, // 倒计时时间（单位：秒）
      amount: 10,
      PGurl: '',
      // orderNo: '',
      intervalId: null, // 存储定时器ID
    }
  },
  created() {
    // if (!localStorage.getItem('getImg')) {
    //   console.log(2333333333333)
      this.getImg()
    // }
    localStorage.removeItem('payment')
    if (localStorage.getItem('startTime') == null) {
      localStorage.setItem('remainingTime', '1800');
      localStorage.setItem('startTime', true);
    }
    console.log(333, this.PGurl)
  },
  mounted() {
    this.stepActive = 1
    this.orderSeconds = localStorage.getItem('remainingTime');
  },
  beforeDestroy() {
    clearInterval(this.countdownTimer);
    clearInterval(this.interval)
    clearInterval(this.intervalId); // 停止定时器
    // localStorage.removeItem('getImg')
  },
  watch: {
  },
  methods: {
    getStatus() {
      this.intervalId = setInterval(() => {
        if (this.orderSeconds == 0) {
          clearInterval(this.intervalId); // 停止定时器
        } else {
          this.$api.queryIsMember({ accountId: localStorage.getItem('userId') }).then((res) => {
            if (res.message == '该用户是会员!') {
              localStorage.setItem('payment', true)
              this.stepActive = 2
              clearInterval(this.intervalId); // 停止定时器
              this.interval = setInterval(() => {
                if (this.seconds > 1) {
                  this.seconds--;
                } else {
                  clearInterval(this.interval)
                  this.goNew('/')
                }
              }, 1000);
            }
          }).catch(() => {
          });
        }
      }, 5000); // 每隔5秒请求一次接口
    },
    getImg() {
      // localStorage.setItem('getImg', true)
      this.loading = true
      let q = {
        accountId: localStorage.getItem('userId'),
        paymentAmount: this.amount * 100
      }
      this.startCountdown()
      this.PGurl = '/api/skNewMedia/media/wechat/member/getCode?accountId=' + q.accountId + '&paymentAmount=' + q.paymentAmount
      this.getStatus()
      this.loading = false
    },
    startCountdown() {
      if (this.countdownTimer) {
        clearInterval(this.countdownTimer); // 如果计时器存在，清除之前的计时器
      }
      // 更新倒计时显示的函数
      const updateCountdown = () => {
        // 计算剩余的分钟数和秒数
        const minutes = Math.floor(this.orderSeconds / 60);
        const seconds = this.orderSeconds % 60;
        // 格式化为“分:秒”的形式
        const formattedTime = ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
        // 将格式化后的时间显示在页面上
        document.getElementById('countdown').innerHTML = formattedTime;
        // 如果倒计时已经结束，则停止更新
        if (this.orderSeconds == 0) {
          clearInterval(this.countdownTimer);
          this.$confirm('未在规定时间内支付,请前往首页重新发起支付!', '系统提示', {
            confirmButtonText: '确定',
            showCancelButton: false,
            type: 'warning'
          }).then(() => {
            localStorage.setItem('payment', false)
            localStorage.removeItem('startTime')
            localStorage.removeItem('remainingTime')
            // localStorage.removeItem('payment')
            this.goNew('/')
          });
        } else {
          // 否则，继续倒计时
          this.orderSeconds--
          localStorage.setItem('remainingTime', this.orderSeconds.toString());
        }
      };
      // 每秒钟更新一次倒计时
      this.countdownTimer = setInterval(updateCountdown, 1000);
    },
    goNew(val) {
      if (this.$route.path !== val) {
        this.$router.push(val);
      }
    },
  }
}
</script>
       
<style scoped>
.alt-multiline {
  /* 这个属性用来保留换行符，并且允许换行显示 */
  white-space: pre-line;
  color: red;
  font-size: 14px;
  border: 1px solid #b7b5b5;
  min-width: 100px;
  min-height: 100px;
}

.order-icon {
  font-size: 42px;
  vertical-align: middle;
  color: #83b24c;
  margin: 0 20px;
}

.order-box {
  display: inline-block;
  vertical-align: middle;
}

.order-box h3 {
  display: inline-block;
  margin: 5px 20px 5px 0;
}

.register-main-top {
  position: relative;
  width: 50%;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.register-container {
  width: 40%;
  /* height: 400px; */
  margin: 0 auto;
  padding: 30px 0;
}

.search-logo {
  position: absolute;
  left: 0;
}

.search-logo img {
  margin-top: 10px;
  margin-right: -30px;
  vertical-align: middle;
}

.search-logo span {
  color: #2A2A2A;
  font-weight: 500;
  font-size: 24px;
  vertical-align: middle;
  font-family: YouYuan;
  margin-right: 5px;
}

.register-form {
  /* text-align: center; */
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}


.arrow-right {
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 18px solid #a9c886;
}

.arrow-right-avtive {
  border-left: 18px solid #83b24c;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 18px solid #f7f7f7;
  margin-right: -18px;
  z-index: 9;
}

.content {
  background-color: #000;
  color: #fff;
  /* padding: 10px; */
}

.step-box {
  width: 40%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  text-align: center;
  background: #f7f7f7;
}

.active-step {
  background-color: #83b24c !important;
  color: #fff;
}

.first-step {
  background-color: #a9c886 !important;
  color: #fff;
}

.step-son {
  width: 50%;
  padding: 8px 0;
  background-color: #eeeeee;
}

.el-button--primary {
  background: linear-gradient(180deg, rgb(172, 204, 137) 0%, #83b24c 100%);
  border-color: #83b24c;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background: linear-gradient(180deg, rgb(172, 204, 137) 0%, #83b24c 100%);
  border-color: rgb(172, 204, 137);
  color: #FFF;
}

.register-goLogin {
  cursor: pointer;
  text-decoration: underline;
}

.register-goLogin:hover {
  color: red;
}

.buttom-txt {
  text-align: center;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: #9E9E9E;
}

.a-herf {
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: #9E9E9E;
  text-align: center;
}

.a-herf:hover {
  color: #6F9B3C;
}
</style>